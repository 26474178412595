<template>
  <vx-card :title="id === null ? $t('cadastrar-grupo') : $t('editar-grupo')">
    <vs-tabs :color="colorx">
      <vs-tab :label="$t('display_name')">
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <vs-input class="w-full" :label="$t('name')" v-model="display_name" />
            <span class="text-danger text-sm" v-show="errors.has('display_name')">{{ errors.first('display_name') }}</span>
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <vs-input class="w-full" type="email" :label="$t('description')" v-model="description" />
            <span
              class="text-danger text-sm"
              v-show="errors.has('description')"
            >{{ errors.first('description') }}</span>
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <label>{{ $t('fields.institution') }}</label>
            <vs-divider class="mt-0"/>
            <select-suggestion
              column="id,name"
              v-permission="'general_system_admin'"
              model="Institution"
              v-model="institution"
              :appendClearOption="true"
              placeholderText="Digite o nome ou id da instituição"
            />
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col w-full">
            <vs-button class="float-right" color="primary" type="border" @click="cancel">{{ $t('action.cancel') }}</vs-button>
            <vs-button class="float-right mr-2" @click="create">{{ $t('action.save') }}</vs-button>
          </div>
        </div>
      </vs-tab>
      <vs-tab :label="$t('functionality.titles')" v-if="id !== null">
        <vs-collapse accordion type="border">
          <vs-collapse-item v-for="func in functionalityList" :key="func.id">
            <div slot="header">
              {{func.name}}
            </div>
            <vs-table no-data=" " :data="func.permissions" :key="id">
                <template slot="thead">
                  <vs-th width="3%">
                    <vs-checkbox :id="`func_id_${func.id}`"
                      v-on:change="selectPermissionsAll($event, func.id, func.permissions)"/>
                    </vs-th>
                  <vs-th width="5%">{{ $t('id') }}</vs-th>
                  <vs-th width="25%">{{ $t('name') }}</vs-th>
                  <vs-th width="20%">{{ $t('permissions_name') }}</vs-th>
                  <vs-th width="50%">{{ $t('description') }}</vs-th>
                </template>
                <template slot-scope="{ data }">
                  <vs-tr
                    :key="i"
                    v-for="(tr, i) in data">
                    <vs-td :data="data[i].id">
                      <vs-checkbox :id="`permission_id_${data[i].id}`"
                        :vs-value="data[i].id"
                        :checked="data[i].checked"
                        v-on:change="selectPermission($event, func.id, data[i].id)"/>
                    </vs-td>
                    <vs-td :data="data[i].id">
                      <label>{{data[i].id}}</label>
                    </vs-td>
                    <vs-td :data="data[i].name">
                      <label>{{data[i].name}}</label>
                    </vs-td>
                    <vs-td :data="data[i].display_name">
                      <label>{{data[i].display_name}}</label>
                    </vs-td>
                    <vs-td :data="data[i].description">
                      <label>{{data[i].description}}</label>
                    </vs-td>
                  </vs-tr>
                </template>
              </vs-table>
          </vs-collapse-item>
        </vs-collapse>
        <div class="vx-row mt-3">
          <div class="vx-col w-full">
            <vs-button class="float-right" color="primary" type="border" @click="cancel">{{ $t('back') }}</vs-button>
          </div>
        </div>
      </vs-tab>
      <vs-tab :label="$t('permissoes')" v-if="id !== null">
        <div class="vx-row mb-6" style="padding-top: 30px">
          <div class="vx-col w-full">
            <vs-button @click="showModal = true" icon="add">{{ $t('adicionar') }}</vs-button>
            <vs-divider />
            <Grid
              :ref="'grid'"
              :key="gridKey"
              :service="permissionService"
              route_name="permissions"
              :route_grid_path="permissionsRolePath"
              :route_delete_path="permissionsRolePath"
              hide_edit="true"
              :multipleSelect="true"
              order_column="name"
              @changedSelection="changedSelection"
              :delegate="{destroy: () => destroyPermissionRole}">
              </Grid>
              <div class="vx-row mt-3">
                <div class="vx-col w-full">
                  <vs-button class="float-right"
                              color="primary"
                              @click="confirmDeletePermissions"
                              v-if="enableActionDeletePermissions">
                    {{ $t('action.delete') }}
                  </vs-button>
                </div>
              </div>
          </div>
        </div>
      </vs-tab>
    </vs-tabs>

    <vs-popup :title="$t('permissoes')" :active.sync="showModal">
      <Grid
        :multipleSelect="true"
        :service="permissionService"
        @changedSelection="changedSelection"
        :selectAllCallback="selectAll"
        route_name="permissions"
        hide_actions="true"
        order_column="name"
        v-if="showModal"></Grid>
      <vs-button @click="addPermissions">
        {{ $t('adicionar') }}
      </vs-button>
    </vs-popup>
  </vx-card>
</template>

<script>
import PermissionService from '@/services/api/PermissionService'
import RoleService from '@/services/api/RoleService'
import FunctionalityService from '@/services/api/FunctionalityService'
import SelectSuggestion from '@/components/SelectSuggestion.vue'

export default {
  components: { SelectSuggestion },
  props: {
    id: {
      type: Number,
      default: null
    }
  },
  data: () => ({
    id: null,
    name: '',
    display_name: '',
    description: '',
    roleService: null,
    permissionService: null,
    functionalityService: null,
    showModal: false,
    gridKey: 0,
    selectedPermissions: [],
    functionalityList: [],
    institution: {
      id: null,
      name: ' '
    }
  }),
  computed: {
    permissionsRolePath() {
      return `/roles/${this.id}`
    },
    enableActionDeletePermissions() {
      return this.selectedPermissions.length > 0
    }
  },
  methods: {
    gridRef() {
      return this.$refs['grid']
    },
    updateGridRefPermissions() {
      const grid = this.gridRef()
      if ('fetchGridData' in grid) {
        grid.fetchGridData()
      }
    },
    changedSelection(items) {
      this.selectedPermissions = items
    },
    addPermissions() {
      if (this.id) {
        this.$vs.loading()
        this.roleService.attachPermissions(this.id, this.selectedPermissions).then(
          (response) => {
            this.showModal = false
            this.selectedPermissions = []
            this.updateGridRefPermissions()
            this.notifySuccess(this.$vs, this.$t('permissoes-adicionadas-com-sucesso'))
            this.$vs.loading.close()
          },
          (error) => {
            this.notifyError(this.$vs, this.$t('houve-um-erro-ao-adicionar-as-permissoes'))
            this.$vs.loading.close()
          }
        )
      }
    },
    addPermissionsByFunctionality(functionalityId, permissionsList) {
      if (this.id) { //this.id = role id
        this.roleService.attachPermissions(this.id, permissionsList).then(
          (response) => {
            this.notifySuccess(this.$vs, this.$t('permissoes-adicionadas-com-sucesso'))
            this.$vs.loading.close()
          },
          (error) => {
            this.notifyError(this.$vs, this.$t('houve-um-erro-ao-adicionar-as-permissoes'))
            this.$vs.loading.close()
          }
        )
      }
    },
    deletePermissionsByRole(permissionsList) {
      if (this.id) { //this.id = role id
        this.roleService.destroyPermissions(this.id, permissionsList).then(
          (response) => {
            this.$vs.notify({
              title: 'Successo!',
              text: this.$t('operacao-realizada-com-sucesso'),
              color: 'success',
              iconPack: 'feather',
              position: 'top-center',
              icon: 'icon-check-circle'
            })
            this.selectedPermissions = []
            this.updateGridRefPermissions()
            this.$vs.loading.close()
          },
          (error) => {
            this.notifyError(this.$vs, this.$t('nao-foi-possivel-excluir-esta-permissao'))
            this.$vs.loading.close()
          }
        )
      }
    },
    selectAll() {
      if (this.permissionService)  {
        this.$vs.loading()
        return this.permissionService.selectAll().then(
          (response) => {
            this.$vs.loading.close()

            return this._.isArray(response) ? response : []
          },
          (error) => {
            this.$vs.loading.close()
            return []
          }
        )
      }
    },
    itemSelected(permission) {
      const permissionIndex = this.selectedPermissions.findIndex((_permission) => _permission === permission.id)

      if (permissionIndex !== -1) {
        const newArray = this._.cloneDeep(this.selectedPermissions)
        newArray.splice(permissionIndex, 1)
        this.selectedPermissions = newArray
        // delete this.selectedPermissions[permissionIndex]
      } else {
        this.selectedPermissions.push(permission.id)
      }

    },
    destroyPermissionRole(permission) {
      this.$vs.loading()
      this.permissionService.destroyPermissionRole(permission.id, this.id).then(
        response => {
          this.$vs.loading.close()
          this.$vs.notify({
            title: 'Successo!',
            text: this.$t('operacao-realizada-com-sucesso'),
            color: 'success',
            iconPack: 'feather',
            position: 'top-center',
            icon: 'icon-check-circle'
          })

          this.gridKey += 1
        },
        error => {
          this.$vs.loading.close()
          this.notifyError(this.$vs, this.$t('nao-foi-possivel-escluir-este-grupo'))
        }
      )
    },
    create() {
      this.clearErrors(this.$validator)
      this.$vs.loading()

      this.roleService
        .createOrUpdate({
          id: this.id,
          display_name: this.display_name,
          description: this.description,
          institution_id: this._.get(this.institution, 'id', null)
        })
        .then(
          data => {
            this.$vs.loading.close()

            this.$vs.notify({
              title: 'Successo!',
              text: this.$t('operacao-realizada-com-sucesso'),
              color: 'success',
              iconPack: 'feather',
              position: 'top-center',
              icon: 'icon-check-circle'
            })

            this.$router.push(`/roles/${data.id}/edit`)
          },
          error => {
            this.$vs.loading.close()
            this.showErrors(this.$validator, error)
          }
        )
    },
    cancel() {
      this.$router.push('/roles')
    },
    getData(id) {
      this.$vs.loading()
      this.roleService.read(id).then(
        data => {
          this.$set(this.$data, 'id', data.id)
          this.$set(this.$data, 'display_name', data.display_name)
          this.$set(this.$data, 'description', data.description)
          this.institution = data.institution
          this.gridKey++
          this.$vs.loading.close()
        },
        error => {
          this.$vs.loading.close()
        }
      )
    },
    getFunctionalityList() {
      this.$vs.loading()
      this.functionalityService.listAllWithPermissions(this.id).then(
        data => {
          this.functionalityList = data
          this.$vs.loading.close()
        },
        error => {
          this.$vs.loading.close()
        }
      )
    },
    selectPermission(event, funcId, permissionId) {
      const permissionsId = [permissionId]
      if (event.target.checked) {
        this.addPermissionsByFunctionality(funcId, permissionsId)
      } else {
        this.deletePermissionsByRole(permissionsId)
      }
    },
    selectPermissionsAll(event, funcId, permissionList) {
      const prefixId = '#permission_id_'
      if (event.target.checked) {
        const ids = []
        permissionList.forEach((permission) => {
          ids.push(permission.id)
          const input = this.$el.querySelector(prefixId + permission.id)
          input.checked = true
        })

        this.addPermissionsByFunctionality(funcId, ids)
      } else {
        const ids = []
        permissionList.forEach((permission) => {
          const input = this.$el.querySelector(prefixId + permission.id)
          ids.push(permission.id)
          input.checked = false
        })

        this.deletePermissionsByRole(ids)
      }
    },
    confirmDeletePermissions() {
      this.$vs.dialog({
        type: 'confirm',
        color: 'success',
        title: this.$t('confirmacao'),
        acceptText: this.$t('sim'),
        cancelText: this.$t('nao'),
        text: this.$t('tem-certeza-que-deseja-excluir-este-registro'),
        accept: this.deletePermissions,
      })
    },
    deletePermissions() {
      this.deletePermissionsByRole(this.selectedPermissions)
    }
  },
  mounted() {
    this.roleService = RoleService.build(this.$vs)
    this.permissionService = PermissionService.build(this.$vs)
    this.functionalityService = FunctionalityService.build(this.$vs)

    if (this.id) {
      this.getData(this.id)
      this.getFunctionalityList()
    }
  }
}
</script>


<style lang="scss" scoped>
::v-deep .vs-popup {
  width: 800px !important;
}
</style>
