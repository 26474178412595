import Rest from '../Rest'

export default class PermissionService extends Rest {
  /**
   * @type {String}
   */
  static resource = '/api/v1/permissions'

  static build(vs: any = null) {
    return new this(this.resource, null, vs)
  }

  destroyPermissionRole(permissionId: Number, roleId: Number) {
    return this.delete(`${permissionId}/role/${roleId}`)
  }

  attachPermissionRole(permissionId: Number, roleId: Number) {
    return this.post(`${permissionId}/attach`, {
      role_id: roleId
    })
  }

  selectAll() {
    return this.get('/selection')
  }
}
